/* src/styles.css */
.custom-modal-content {
    width: 90%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    background-color: white;
}

@media (max-width: 600px) {
    .custom-modal-content {
        width: 90%;
        max-width: 300px;
        padding: 10px;
    }
}
:root {
    --plyr-color-main: #f56607; /* Change this to your desired color */
}

/* Customize control button colors */
.plyr--audio .plyr__controls button {
    color: #ffffff !important; /* Force button color change */
}

/* Customize the progress bar color */
.plyr--audio .plyr__progress--played {
    background: #3A3B3CFF !important; /* Force progress bar color */
}

/* Customize the background of the control bar */
.plyr--audio .plyr__controls {
    background: #3A3B3CFF !important; /* Force control bar background color */
    border-radius: 8px; /* Optional: Add rounded corners for a cleaner look */
}
