.icon-gradient {
  display: inline-block;
  color: #2c2c2c; /* Adjust these colors based on your gradient */
}

.btn-gradient {
  display: inline-block;
  color: #343434; /* Adjust these colors based on your gradient */
}

/* Additional styles for buttons to ensure the gradient background */
.btn-gradient {
  color: #000000; /* Text color */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  background-clip: border-box;
  background: #f0f0f0; /* Soft neutral background color */
  transition: background 0.3s ease; /* Smooth transition for hover effect */
}

.btn-gradient:hover {
  background: linear-gradient(45deg, #FF7F00, #f56607); /* Hover effect with gradient */
  color: white; /* Ensure text color remains readable on gradient */
}

.btn-gradient-next {
  color: #343434; /* Adjust these colors based on your gradient */
  background: linear-gradient(45deg, #FF7F00, #f56607); /* Hover effect with gradient */
  margin-left: auto; /* This will align the divider to the right */
  text-align: center;
}

.input-error {
  border: 2px solid red;
  background-color: #ffe6e6;
}

.btnik {
  margin: 20px;
  padding: 20px;
}

/* Reset some common styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
}

body {
  background-color: #f8f9fa;

}

html {
  overflow-y: scroll;
}

